<template>
  <div class="pages">
    <div class="task_box">
      <div class="tree">
        <div class="treeConTitle">创建组织名称</div>
        <div class="bts">
           <el-button @click="opens" type="text">创建</el-button>
        </div>
          
        <div class="treeCon">
          <el-tree
            :data="siteList"
            :props="defaultProps"
            node-key="id"
            accordion
            :expand-on-click-node="false"
            :default-expand-all="false"
          >
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <div class="tree_one">{{ data.title }}</div>
              <div class="tree_one">{{ data.user_name }}</div>
              <div class="tree_one">{{ data.user_name }}</div>

              <div class="tree_one">
                <el-button type="text" size="mini" @click="demo_open(data)">
                  添加
                </el-button>
                <el-button type="text" size="mini" @click="edit(data)">
                  修改
                </el-button>
                <el-button type="text" size="mini" @click="del(data)">
                  删除
                </el-button>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
    </div>
    <el-dialog title="添加组织" width="30%" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="组织名称: " :label-width="formLabelWidth">
          <el-input v-model="form.name" placeholder="组织名称" autocomplete="off"></el-input>
        </el-form-item>
         <!-- <el-form-item v-if="cur_type==1" label="设备类型: " :label-width="formLabelWidth">
            <el-select
                  v-model="sheb"
                  placeholder="设备类型"
                  @change="changeRole"
                >
                  <el-option
                    v-for="item in sheList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
        </el-form-item> -->
     
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cur_type:0,
      siteList: [ 
      ],
      defaultProps: {
        children: "children",
        label: "title",
      },
      dialogFormVisible: false,
      form: {
        name: "",
        gateway_id: "",
        city_name: '',
        pid: '', // 父级id
        description: '',
        id:'',
      },
      sheList: [],
      formLabelWidth: "120px",
    };
  },
  mounted() {
    // 获取表格列表数据
    this.getzuzhiList()
  },
  methods: {
    async del(e){
       const res = await this.$http.post('Organize/del',{
         
        id:e.id,
       })
      if (res.code !== 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getzuzhiList()

    },
    //修改
    edit(e){
      console.log(e)
      this.cur_type=1
     this.form.name=e.title
     this.form.pid=e.pid
     this.form.id=e.id
    this.dialogFormVisible = true;

    },
    submit(){
        if(this.cur_type==0){
this.listone()
        }else{
          this.lists()
        }

    },
   async listone(){
       const res = await this.$http.post('Organize/handle',{
          name: this.form.name,
        gateway_id: "",
        city_name: '',
        pid: 0, // 父级id
        description: '',
        id:'',
       })
      if (res.code !== 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getzuzhiList()
      this.dialogFormVisible = false;
    },
    async lists(){
     
      const res = await this.$http.post('Organize/handle', this.form)
      if (res.code !== 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
       this.getzuzhiList()
      
      this.dialogFormVisible = false;
    },
    opens(){
      this.form.name=''
      this.cur_type=0
      this.dialogFormVisible = true;
    },
    demo_open(data) {
      this.form.name=''
      this.cur_type=1
      this.form.pid = data.id;
      this.dialogFormVisible = true;
    },
     // 组织 获取
       async getzuzhiList() {
      const res = await this.$http.post('Organize')
      this.siteList = res.data
    },
  },
};
</script>
<style lang="scss" scoped>
.bts{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 72%;
  }
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.task_box {
  width: calc(100% - 220px);
  display: flex;
  align-items: center;
  justify-content: center;

  .tree {
    width: 1111px;
    height: 806px;
    background: url("../../assets/images/editDialog.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    .treeConTitle {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #4486FF;
      margin: 59px 0 23px 0;
    }
    .treeCon {
      width: 800px;
      height: 424px;
      border: 2px solid #4486FF;
      border-radius: 4px;
      padding: 20px 40px;
      box-sizing: border-box;
    }

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding-right: 30px;
      padding-left: 20px;

    }
    ::v-deep .el-tree{
        background: rgba(0,0,0,0);
        color: #fff;
    }
    ::v-deep .el-tree-node__content{
        height: 44px;
    }
    ::v-deep .el-tree-node:focus > .el-tree-node__content {
        background-color: #66b1ff87 !important;
        }
       ::v-deep .el-tree-node__content:hover {
        background-color: #66b1ff87;
        }
    .el-button--text{
        color: #4486FF;
    }
    .el-button--mini{
        font-size: 16px;
    }
  }
}
</style>